import { Autocomplete, Box, Button, Container, Divider, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AppAutocomplete from '../components/AppAutocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import currency from 'currency.js';

import dayjs from 'dayjs';
import { callApi } from '../utils/request';
import { CsvToCal } from '../utils/csvtocal';
import Alert from '../components/Alert';
import { useNavigate, useParams } from 'react-router-dom';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';

/**
 * currency
 *
 * Package de formatage de devices monétaires.
 *
 * @const currency_opts   défini les options utilisé lors de l'utilisation
 *                        du package currency.
 * @param obj.separator   indique le séparateur de millier.
 * @param obj.decimal     indique le symbole utilisé pour la séparation des décimales.
 * @param obj.precision   indique la précision.
 * @param obj.symbol      indique le symbole de device à utiliser.
 * @param obj.pattern     indique le pattern à utiliser.
 *                        Le configuration actuelle génèrera: # ! -> 1 123,45 EUR
*/
const currency_opts = {
    separator: " ",
    decimal: ",",
    precision: 2,
    symbol: "€",
    pattern: "# !"
};

function CreateContract() {
    const { id } = useParams()
    const navigate = useNavigate()
    const currentContract = id || null
    const [tab, setTab] = useState<String>('csv');
    const [contratInfo, setContratInfo] = useState<any | null>(null);
    const [csvContract, setCsvContract] = useState<string>("")
    const [canGenerateContractForce, setCanGenerateContractForce] = useState<any | null>(null);
    const [alert, setAlert] = useState<CustomAlertType | null>(null)
    const initialState = {
        label: null,
        ref: '',
        school: null,
        campus: null,
        promotion: null,
        trainer: null,
        society: null,
        signatory: '',
        type: { label: 'classique', id: 1 },
        Documents: [],
        startAt: '',
        endAt: '',
        unit: '',
        price: '',
        number: '',
        currency: ''
    }
    const [contract, setContract] = useState<any>(initialState)
    const [documentsList, setDocumentsList] = useState<AppDocumentType[]>([])
    const currencies = {
        EUR: '€',
        USD: '$'
    }

    useEffect(() => {
        !id && setContract({ ...initialState, Documents: documentsList.filter(d => d.default === true) })
    }, [id, documentsList])

    useEffect(() => {
        if (currentContract) {
            callApi('get', '/manage/contract/' + currentContract).then(r => {
                if (r.id) {
                    setContract({
                        label: r.Label,
                        ref: r.ref || '',
                        school: { id: r.Promotion.Campus.School.id, label: r.Promotion.Campus.School.label, from: 'school' },
                        campus: { SchoolId: r.Promotion.Campus.SchoolId, id: r.Promotion.Campus.id, label: r.Promotion.Campus.label, from: 'campus' },
                        promotion: { CampusId: r.Promotion.CampusId, id: r.Promotion.id, label: r.Promotion.label },
                        trainer: r.Trainer,
                        signatory: r.Signatory ? { id: r.Signatory.id, label: r.Signatory.label } : null,
                        //type: r.Type ? { id: r.Type.id, label: r.Type.label } : null,
                        type: r.Type ? { id: r.Type.id, label: r.Type.label } : { label: 'classique', id: 1 },
                        society: r.Society ? { id: r.Society.id, label: r.Society.label, from: 'society' } : null,
                        Documents: r.Documents || [],
                        startAt: dayjs(r.startAt) || '',
                        endAt: dayjs(r.endAt) || '',
                        unit: r.unit || '',
                        price: r.price || '',
                        number: r.number || '',
                        currency: r.currency || ''
                    })
                }
                else {
                    navigate('/dashboard/create-contract')
                }
            }, () => { })
        }
        callApi('get', '/manage/document').then(r => {
            setDocumentsList(r)
        }, () => { })
    }, [])

    useEffect(() => {
        handlerImportCSV(csvContract)
    }, [csvContract])

    const handleSave = (callback?: ((id?: string) => void) | null) => {
        const contractSaved = {
            ...contract,
            LabelId: contract.label && contract.label.id,
            TrainerId: contract.trainer && contract.trainer.id,
            PromotionId: contract.promotion && contract.promotion.id,
            TypeId: contract.type && contract.type.id,
            startAt: moment(contract.startAt).unix(),
            endAt: moment(contract.endAt).unix(),
            price: (contract.price && parseFloat(contract.price).toFixed(2)) || null,
            number: (contract.number && parseFloat(contract.number).toFixed(2)) || null,
            SocietyId: contract.society && contract.society.id,
            SignatoryId: contract.signatory && contract.signatory.id
        }

        delete contractSaved.trainer
        delete contractSaved.type
        delete contractSaved.label
        delete contractSaved.promotion
        delete contractSaved.school
        delete contractSaved.campus
        delete contractSaved.society
        delete contractSaved.signatory

        Object.keys(contractSaved).forEach(k => contractSaved[k] = contractSaved[k] === '' ? null : contractSaved[k])
        callApi(currentContract ? 'put' : 'post', '/manage/contract/' + (currentContract ? currentContract : ''), contractSaved).then(r => {
            if (callback) {
                callback(r.data?.id ? r.data.id : currentContract)
            }
            else {
                if (r.alert) {
                    setAlert(r.alert)
                    if (r.alert.variant === 'success') {
                        setContract(initialState)
                        navigate('/dashboard/create-contract')
                    }
                }
            }
        }, () => { })
    }

    const handleChange = (name: string, value?: { id: number, from: string, Signatory?: any } | string | null) => {
        switch (name) {
            case 'school':
                return setContract({ ...contract, campus: null, promotion: null, [name]: value ? value : null })
            case 'campus':
                return setContract({ ...contract, promotion: null, [name]: value ? value : null })
            case 'society':
                return setContract({ ...contract, signatory: null, [name]: value ? value : null })
            default:
                return setContract({ ...contract, [name]: value ? value : null })
        }
    }

    const canGenerateContract = () => {
        let complete = true
        Object.keys(contract).forEach((k) => {
            if ((!contract[k] && k !== 'unit' && k !== 'number') || (k === 'unit' && contract[k] !== 'forfait' && !contract.number)) {
                complete = false
            }
        })
        return !complete
    }

    const handleGenerateContract = (e: any) => {
        e.target.disabled = true

        if (canGenerateContractForce !== null) {
            canGenerateContractForce.cnt_documents = contract.Documents
            setAlert({ variant: "info", message: "Génération en cours..." })
            callApi('post', "/manage/contract/csv", canGenerateContractForce).then(r => {
                e.target.disabled = false
                if (r.alert) {
                    if (r.alert.variant === 'success') {
                        setTimeout(() => navigate('/dashboard/generated-contracts'), 2500);
                    } else {
                        setCsvContract("");
                        setContratInfo(null);
                        setCanGenerateContractForce(null);
                    }

                    setAlert(r.alert)
                } else {
                    setAlert({ variant: "error", message: "Une erreur s'est produite." })
                }
            })
        } else {
            handleSave((currentId) => {
                if (currentId) {
                    callApi('post', '/manage/contract/generate/' + currentId).then(r => {
                        e.target.disabled = false
                        if (r.alert) {
                            setAlert(r.alert)
                            if (r.alert.variant === 'success') {
                                setTimeout(() => {
                                    setContract(initialState)
                                    navigate('/dashboard/create-contract')
                                }, 2000);
                            }
                        }
                    })
                }
            })
        }
    }

    // Handler exécuté lorsque l'utilisateur veut créer une facture depuis un CSV.
    const handlerImportCSV = async (csv: any) => {
        if (!csv || csv === "") return;
        setContratInfo(null);
        setCanGenerateContractForce(null);

        const result = CsvToCal(csv);
        if (result instanceof Error) {
            setAlert({ variant: "warning", message: result.toString() });
        } else {
            result.cnt_documents = contract.Documents;
            setContratInfo(result);
            setCanGenerateContractForce(result);
        }
    };

    return (
        <Box sx={{ minHeight: 'calc(100vh - 64px - 24px * 5)' }}>
            {alert && <Alert alert={alert} />}
            <Typography variant='h6'>Créer un contrat</Typography>
            <Divider sx={{ my: 2 }} />
            <ToggleButtonGroup
                color="primary"
                value={tab}
                exclusive
                sx={{ justifyContent: 'center', display: 'flex', mb: 2 }}
                onChange={(
                    event: React.MouseEvent<HTMLElement>,
                    newTab: string | null,
                ) => {
                    if (newTab) {
                        setTab(newTab);
                    }
                }}
                aria-label="Platform"
            >
                <ToggleButton value="form">Formulaire</ToggleButton>
                <ToggleButton value="csv">Importation CSV</ToggleButton>
            </ToggleButtonGroup>
            <Container
                component='form'
            >
                {tab === 'form' && <>
                    <Grid item xs={12}>
                        <TextField
                            type='text'
                            fullWidth
                            value={contract.ref}
                            label='N° du contrat'
                            onChange={(e) => setContract({ ...contract, ref: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                    </Grid>
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={12} md={6}>
                            <AppAutocomplete
                                onChange={handleChange}
                                name='label'
                                currentValue={contract.label}
                                label='Intitulé de la mission'
                                creatable
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AppAutocomplete
                                onChange={handleChange}
                                name='school'
                                currentValue={contract.school}
                                label='Ecole'
                                creatable
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AppAutocomplete
                                onChange={handleChange}
                                disabled={!contract.school ? true : false}
                                name='campus'
                                relation={contract.school}
                                currentValue={contract.campus}
                                label='Campus'
                                creatable
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AppAutocomplete
                                onChange={handleChange}
                                disabled={!contract.campus || !contract.school ? true : false}
                                name='promotion'
                                relation={contract.campus}
                                currentValue={contract.promotion}
                                label='Promotion'
                                creatable
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <AppAutocomplete
                                onChange={handleChange}
                                name='trainer'
                                currentValue={contract.trainer}
                                label='Formateur'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppAutocomplete
                                onChange={handleChange}
                                name='society'
                                currentValue={contract.society}
                                label='Société'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <TextField
                            value={contract.society ? contract.signatory.label : ''}
                            label='Signataire'
                            disabled={true}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        /> */}
                            <AppAutocomplete
                                name='signatory'
                                onChange={handleChange}
                                currentValue={contract.signatory}
                                disabled={contract.society ? false : true}
                                relation={contract.society}
                                label='Signataire'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid item xs={12} md={6}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        value={contract.startAt || null}
                                        onChange={(e) =>
                                            setContract({ ...contract, startAt: dayjs(e) })
                                        }
                                        format='YYYY-MM-DD'
                                        label="Début"
                                        sx={{ width: '100%' }}
                                    />
                                </DemoContainer>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        value={contract.endAt || null}
                                        onChange={(e) =>
                                            setContract({ ...contract, endAt: dayjs(e) })
                                        }
                                        format='YYYY-MM-DD'
                                        label="Fin"
                                        sx={{ width: '100%' }}
                                    />
                                </DemoContainer>
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type='number'
                                fullWidth
                                value={contract.number}
                                label='Nombre'
                                disabled={contract.unit === 'forfait' || !contract.unit ? true : false}
                                onChange={(e) => setContract({ ...contract, number: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="unit-label">Unitée</InputLabel>
                                <Select
                                    labelId='unit-label'
                                    label='Unitée'
                                    id='unit-label-select'
                                    value={contract.unit}
                                    fullWidth
                                    onChange={(e) => {
                                        setContract({ ...contract, unit: e.target.value, number: e.target.value === 'forfait' ? '' : contract.number })
                                    }}
                                >
                                    <MenuItem value={'heure'}>heure</MenuItem>
                                    <MenuItem value={'jour'}>jour</MenuItem>
                                    <MenuItem value={'forfait'}>forfait</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type='number'
                                fullWidth
                                value={contract.price}
                                InputProps={{
                                    startAdornment: contract.currency && <InputAdornment position="start">{currencies[contract.currency as 'EUR' | 'USD']}</InputAdornment>,
                                }}
                                label='Prix par unitée'
                                onChange={(e) => setContract({ ...contract, price: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="currency-label">Devise</InputLabel>
                                <Select
                                    labelId='currency-label'
                                    label='Devise'
                                    id='currency-label-select'
                                    value={contract.currency}
                                    fullWidth
                                    onChange={(e) => setContract({ ...contract, currency: e.target.value })}
                                >
                                    {
                                        Object.entries(currencies).map((c) => {
                                            return <MenuItem key={c[0]} value={c[0]}>{c[1]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
                }
                {
                    tab === 'csv' &&
                    <TextField
                        key="csv_textarea"
                        name="csv_textarea"
                        label="CSV"
                        variant="outlined"
                        rows={10}
                        placeholder='Copiez le contenu du planning ici.'
                        sx={{
                            width: '100%',
                            display: "block",
                            "& > div": {
                                width: '100%',
                                fontSize: 12,
                                fontFamily: "serif"
                            }
                        }}
                        multiline
                        // onChange={(event) => handlerImportCSV(event.target.value)}
                        value={csvContract}
                        onChange={(event) => { setCsvContract(event.target.value) }}
                    />
                }
                {
                    (tab === 'csv' && contratInfo !== null) &&
                    <List
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                        }}
                    >
                        <ListItem>
                            <ListItemText primary="Contrat" secondary={contratInfo.cnt_num} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText primary="Prix € HT" secondary={contratInfo.cnt_price_unit === "forfait" ? `${currency(contratInfo.cnt_price, currency_opts).format()} (Forfait)` : `${currency(contratInfo.cnt_price, currency_opts).format()} / ${contratInfo.cnt_price_unit}`} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText primary="Société" secondary={contratInfo.cnt_society.substr(0, 8) === "[INDIV]-" ? "(Individuel)" : contratInfo.cnt_society} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <Typography
                            sx={{ mt: 0.5, ml: 9 }}
                            color="text.secondary"
                            display="block"
                            variant="caption"
                        >
                            Planning
                        </Typography>
                        <ListItem>
                            <List
                                sx={{
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                }}
                            >
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Centre</TableCell>
                                                <TableCell align="right">Session</TableCell>
                                                <TableCell align="right">Horaire</TableCell>
                                                <TableCell align="right">D/P</TableCell>
                                                <TableCell align="right">Date</TableCell>
                                                <TableCell align="right">Formateur</TableCell>
                                                <TableCell align="right">Volume Hrs</TableCell>
                                                <TableCell align="right">Module</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {contratInfo.cnt_planif.map((row: any, index: number) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">{row[0]}</TableCell>
                                                    <TableCell align="right">{row[1]}</TableCell>
                                                    <TableCell align="right">{row[2]}</TableCell>
                                                    <TableCell align="right">{row[3]}</TableCell>
                                                    <TableCell align="right">{moment.unix(row[4]).format("ddd DD MMM YY")}</TableCell>
                                                    <TableCell align="right">{`${row[5]} ${row[6]}`}</TableCell>
                                                    <TableCell align="right">{row[7].toFixed(2)}</TableCell>
                                                    <TableCell align="right">{row[8]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </List>
                        </ListItem>
                    </List>
                }
                <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                </Grid>
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="documents-standard"
                            options={documentsList}
                            getOptionLabel={(option) => option.label}
                            value={documentsList.filter((d: any) => {
                                return contract.Documents.some((e: any) => e.id === d.id)
                            })}
                            onChange={(e, v) => { setContract({ ...contract, Documents: v }) }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Documents"
                                    placeholder="Documents a fournir"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AppAutocomplete
                            onChange={handleChange}
                            name='type'
                            currentValue={contract.type}
                            label='Type de contrat'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                        {
                            tab !== 'csv' &&
                            <Button
                                variant='outlined'
                                onClick={() => handleSave()}
                            >
                                Enregistrer
                            </Button>
                        }
                        <Button
                            variant='outlined'
                            color='success'
                            disabled={tab === "csv" ? !contratInfo : canGenerateContract()}
                            onClick={handleGenerateContract}
                        >
                            Générer le contrat
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    );
}

export default CreateContract;
